<template>
  <div>Intrusion Detection System</div>
</template>
<script setup>
import { useQmoduleBase } from "~/composables/useQmoduleBase";
import { useQmoduleProps } from "~/composables/useQmoduleProps";

const props = defineProps(useQmoduleProps());
const qmoduleBase = useQmoduleBase(props);
console.log("Intrusion Detection System", qmoduleBase);
</script>
